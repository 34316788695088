export const baseURL = "https://backend.givepay.net/api/v1";
// export const baseURL = "http://localhost:5050/api/v1";
export const ACCESS_TOKEN_KEY = "SAID";

export const CLOVER_END_POINT = "https://sandbox.dev.clover.com";
export const CLOVER_TOKEN_END_POINT = "https://apisandbox.dev.clover.com/oauth/token";
export const CLOVER_APP_ID = "DWFKDTQH3832T"
export const CLOVER_APP_SECRET = "5fd8960e-be7e-0406-7224-208f6887c8ef"
export const PUBLIC_URL = "https://sandbox-admin.givepay.net"
// export const PUBLIC_URL = "http://localhost:3000"
